<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="result">
            <h1 class="text-center display-1" :style="{ fontFamily: font }">
              {{ title }}
            </h1>
            <div class="builder">
              <div v-for="n in parseInt(this.count)" :key="n">
                <div
                  :class="shape"
                  :style="{
                    height: itemSize,
                    width: itemSize,
                    marginLeft: itemMargin,
                    marginTop: itemMargin,
                  }"
                >
                  <div
                    class="text"
                    :style="{ fontSize: fontSize, fontFamily: font }"
                    v-if="showNumbers"
                  >
                    {{ moment(n) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "generator",
  props: {
    count: Number,
    title: String,
    shape: String,
    showNumbers: {
      type: Boolean,
      default: true,
    },
    font: {
      type: String,
      default: "Cinzel",
    },
    startDate: String
  },
  methods: {
    moment(date) {
      return moment(this.startDate)
        .add(date - 1, "days") // -1 required to start selected day
        .format("D MMM ");
    },
  },
  computed: {
    itemSize() {
      if (this.shape == "diamond") {
        return Math.round(this.scale * 19) + "px";
      }

      return Math.round(this.scale * 25) + "px";
    },

    itemMargin() {
      if (this.shape == "diamond") {
        return this.scale * 5 + "px";
      }
    },

    fontSize() {
      return (this.scale / 2.3).toFixed(2) + "rem";
    },
    scale() {
      let scale = Math.sqrt(this.pageSize / this.count).toFixed(2);
      console.log("Scale", scale);
      return scale;
    },
  },
  data() {
    return {
      pageSize: 820,
    };
  },
};
</script>

<style lang="scss">
.builder {
  margin-right: 9%;
  margin-left: 9%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  justify-content: center;
}

.builder > div {
  margin: 3px;
  text-align: center;
  font-size: 20px;
  .diamond .first {
    margin: 0 !important;
  }
  .square .first {
    margin: 0 !important;
  }
  .circle .first {
    margin: 0 !important;
  }
}

.builder {
  .text {
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    word-spacing: 4rem;
  }
}

/** Shapes CSS */
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #adb5bd;
  background-color: white;
}

.square {
  height: 50px;
  width: 50px;
  border: 2px solid #adb5bd;
}

.rounded-square {
  height: 50px;
  width: 50px;
  border: 2px solid #adb5bd;
  border-radius: 20%;
}

.diamond {
  transform: rotate(45deg);
  border: 2px solid #adb5bd;
}

.diamond {
  .text {
    transform: rotate(-45deg);
  }
}

.star {
  margin: 50px 0;
  position: relative;
  display: block;
  color: black;
  width: 0px;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid black;
  border-left: 100px solid transparent;
  transform: rotate(35deg);
  background-color: white;
}

.star:before {
  border-bottom: 80px solid black;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -45px;
  left: -65px;
  display: block;
  content: "";
  transform: rotate(-35deg);
}
.star:after {
  position: absolute;
  display: block;
  color: black;
  top: 3px;
  left: -105px;
  width: 0px;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid black;
  border-left: 100px solid transparent;
  transform: rotate(-70deg);
  content: "";
}
</style>
