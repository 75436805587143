const fonts = [
  "Montez",
  "Lobster",
  "Josefin Sans",
  "Shadows Into Light",
  "Pacifico",
  "Amatic SC",
  "Orbitron",
  "Rokkitt",
  "Righteous",
  "Dancing Script",
  "Bangers",
  "Chewy",
  "Sigmar One",
  "Architects Daughter",
  "Abril Fatface",
  "Covered By Your Grace",
  "Kaushan Script",
  "Gloria Hallelujah",
  "Satisfy",
  "Lobster Two",
  "Comfortaa",
  "Cinzel",
  "Courgette"
];

export default fonts;
