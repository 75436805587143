<template>
  <div class="profile-page">
    <link
      :href="'https://fonts.googleapis.com/css?family=' + loadFonts"
      rel="stylesheet"
      type="text/css"
    />

    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew section-generator-settings">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-left">
              <div class="col-lg-8 order-lg-1">
                <h4 class="tabs-title">Personalize Your Habit Tracker</h4>
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex print-button-align-left">
                  <div>
                    <base-button type="success" size="me" class="mr-4" @click="print()">Print</base-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <tabs fill class="flex-column flex-md-row">
              <tab-pane>
                <span slot="title">
                  Basic Settings
                  <i class="fa fa-cog" />
                </span> -->
                <div class="row tabs-gap">
                  <div class="col-md-5">
                    <div>
                      <small class="d-block text-uppercase font-weight-bold mb-3 text-center user-inputs">{{$t("advance.inspiration")}}</small>
                      <base-input
                        placeholder="What would you like to achive?"
                        v-model="insperation"
                        class="title-input"
                      ></base-input>
                    </div>
                  </div>
                  <div class="col-md-2">
                      <small class="d-block text-uppercase font-weight-bold mb-3 text-center user-inputs">{{$t("advance.startDate")}}</small>
                      <base-input addon-left-icon="ni ni-calendar-grid-58">
                          <flat-picker slot-scope="{focus, blur}"
                                      @on-open="focus"
                                      @on-close="blur"
                                      :config="{allowInput: true}"
                                      class="form-control datepicker"
                                      v-model="startDate">
                          </flat-picker>
                      </base-input>
                  </div>
                  <div class="col-md-2">
                    <div>
                      <small class="d-block text-uppercase font-weight-bold mb-3 text-center user-inputs">{{$t("advance.days")}}</small>
                      <base-input placeholder='30 days' v-model="count"></base-input>
                    </div>
                  </div>
                
                  <div class="col-md-2">
                    <div>
                      <small class="d-block text-uppercase font-weight-bold mb-3 text-center user-inputs">{{$t("advance.font")}}</small>
                      <base-dropdown class="fontSelect">
                        <base-button
                          slot="title"
                          type="secondary"
                          class="dropdown-toggle font-picker-text"
                        >Font ( {{font}} )</base-button>
                        <a
                          @click="changeFont(option)"
                          @mouseover="changeFont(option)"
                          class="dropdown-item"
                          href="#"
                          v-bind:key="option"
                          v-for="option in fonts"
                          :style="{ fontFamily: option }"
                        >{{ option }}</a>
                      </base-dropdown>
                    </div>
                  </div>
                </div>
                <div class="col-md-1" />
              <!-- </tab-pane> -->
              <!-- <tab-pane>
                <span slot="title">
                  Advance Settings
                  <i class="fa fa-cogs" />
                </span>
                <div class="row tabs-gap">
                  <div class="col-md-1" />
                  <div class="col-md-4">
                    <div>
                      <h4 class="settings-title">Change shape</h4>
                      <select class="form-control" v-model="shape">
                        <option
                          v-for="option in shapes"
                          v-bind:value="option.value"
                          v-bind:key="option.value"
                        >{{ option.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div>
                      <h4 class="settings-title">Show Numbers:</h4>
                      <base-switch v-model="showNumbers"></base-switch>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                </div>
              </tab-pane>
            </tabs> -->
            <div></div>
            <generator
              id="printMe"
              :font="font"
              :title="insperation"
              :count="itemCount"
              :shape="shape"
              :showNumbers="showNumbersStatus"
              :startDate="startDate"
            ></generator>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import Generator from "./components/Generator";
import BaseDropdown from "@/components/BaseDropdown";
import { Printd } from "printd";
import fontList from "../fonts";
import Tabs from "@/components/Tabs/Tabs";
import TabPane from "@/components/Tabs/TabPane";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "advance",
  components: {
    Generator,
    BaseDropdown,
    Tabs,
    TabPane,
    flatPicker,
  },
  computed: {
    itemCount() {
      return parseInt(this.count);
    },
    showNumbersStatus() {
      return Boolean(this.showNumbers);
    },
    loadFonts() {
      return this.fonts.join("|");
    }
  },
  methods: {
    changeFont(font) {
      this.font = font;
    },
    print() {
      let documentTitle = this.insperation;
      const d = new Printd();
      d.print(
        document.getElementById("printMe"),
        [
          "/app.css",
          "/print.css",
          "https://fonts.googleapis.com/css?family=" + this.font
        ],
        ["(()=> console.log('Hello world from IFrame!'))()"],
        ({ launchPrint }) => {
          document.title = "XDaysHabit - " + documentTitle;
          launchPrint();
        }
      );
    }
  },
  data() {
    return {
      showNumbers: true,
      count: parseInt(100),
      insperation: "Challenge Accepted!",
      shape: "circle",
      startDate: new Date().toISOString().slice(0,10),
      shapes: [
        { text: "Circle (default)", value: "circle" },
        { text: "Rounded Square", value: "rounded-square" },
        { text: "Square", value: "square" },
        { text: "Diamond", value: "diamond" },
      ],
      font: "Montez",
      fonts: fontList,
    };
  }
};
</script>
<style>
.tabs-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
}
.settings-title {
  text-align: left;
  font-size: 1.1rem !important;
}
.tabs-gap {
  margin-top: 1.5rem !important;
}
.print-button-align-left {
  float: right;
}
.title-input input {
  font-size: 1.2rem !important;
}
.section-generator-settings {
  margin-top: -200px;
}
.font-picker-text {
  text-transform: capitalize;
}
.settings-title {
  color: grey;
}
.fontSelect .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.numberCircle {
  font: 32px Arial, sans-serif;

  width: 2em;
  height: 2em;
  box-sizing: initial;

  background: #fff;
  border: 0.1em solid #666;
  color: #666;
  text-align: center;
  border-radius: 50%;

  line-height: 2em;
  box-sizing: content-box;
}
.user-inputs {
  text-align: left !important;
}
</style>
