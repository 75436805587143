import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import messages from "./language";
import Argon from "./plugins/argon-kit";
import "./registerServiceWorker";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(Argon);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
