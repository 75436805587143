<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="display-3 text-white">
                  {{$t('landing.heroTitle')}}
                  <!-- <span>with small steps</span> -->
                </h1>
                <p class="lead text-white">
                  {{$t('landing.heroDescription')}}
                </p>
                <div class="btn-wrapper">
                  <base-button
                    tag="a"
                    href="/advance"
                    class="mb-3 mb-sm-0"
                    type="info"
                    icon="fa fa-code"
                  >
                    {{ $t('landing.createYourOwn') }}
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon
                    name="fa fa-heart"
                    type="primary"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h6 class="text-primary text-uppercase">{{$t('landing.challenges.sugarFree')}}</h6>
                  <p class="description mt-3">
                   21-Day Sugar-Free Challenge can help you kick your sugar habit and develop healthier eating habits for life.
                  </p>
                  <div>
                    <badge type="primary" rounded>eating</badge>
                    <badge type="primary" rounded>habits</badge>
                    <badge type="primary" rounded>health</badge>
                  </div>
                  <base-button tag="a" href="./sugar_free_21_days.pdf" type="primary" class="mt-4">
                    {{$t('landing.download')}}
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon
                    name="fa fa-book"
                    type="success"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h6 class="text-success text-uppercase">{{$t('landing.challenges.reading')}}</h6>
                  <p class="description mt-3">
                    With consistent effort, you'll be surprised at how many books you can complete over time
                  </p>
                  <div>
                    <badge type="success" rounded>reading</badge>
                    <badge type="success" rounded>habits</badge>
                    <badge type="success" rounded>life</badge>
                  </div>
                  <base-button tag="a" href="./66_days_reading.pdf" type="success" class="mt-4">
                    {{$t('landing.download')}}
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon name="fa fa-smile-o" type="warning" rounded class="mb-4">
                  </icon>
                  <h6 class="text-warning text-uppercase">{{$t('landing.challenges.happy')}}</h6>
                  <p class="description mt-3">
                    Capture a happy moment every day and start and end your day on a positive note for 100 days.
                  <div>
                    <badge type="warning" rounded>Life</badge>
                    <badge type="warning" rounded>experiment</badge>
                    <badge type="warning" rounded>routine</badge>
                  </div>
                  <base-button tag="a" href="./100_happy_days.pdf" type="warning" class="mt-4">
                    {{$t('landing.download')}}
                  </base-button>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="img/theme/design-page.png" class="img-fluid floating" />
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <!-- <icon
                name="ni ni-settings-gear-65"
                class="mb-5"
                size="lg"
                type="success"
                shadow
                rounded
              ></icon> -->
              <h3>Create a Customized Habit Tracker</h3>
              <p>We think it's super important to have a personalized tracker that you love to use every day.</p>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge
                      type="success"
                      circle
                      class="mr-3"
                      icon="fa fa-lightbulb-o"
                    ></badge>
                    <h6 class="mb-0">{{$t('landing.features.inspire')}}</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge
                      type="success"
                      circle
                      class="mr-3"
                      icon="fa fa-arrows-alt"
                    ></badge>
                    <h6 class="mb-0">{{$t('landing.features.target')}}</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge
                      type="success"
                      circle
                      class="mr-3"
                      icon="fa fa-font"
                    ></badge>
                    <h6 class="mb-0">{{$t('landing.features.font')}}</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge
                      type="success"
                      circle
                      class="mr-3"
                      icon="fa fa-square"
                    ></badge>
                    <h6 class="mb-0">{{$t('landing.features.shape')}}</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge
                      type="success"
                      circle
                      class="mr-3"
                      icon="fa fa-print"
                    ></badge>
                    <h6 class="mb-0">{{$t('landing.features.print')}}</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="card bg-default shadow border-0">
              <img
                v-lazy="'img/theme/steps.jpg'"
                class="card-img-top"
              />
              <!-- <blockquote class="card-blockquote">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="svg-bg"
                >
                  <polygon
                    points="0,52 583,95 0,95"
                    class="fill-default"
                  ></polygon>
                  <polygon
                    points="0,42 583,95 683,0 0,95"
                    opacity=".2"
                    class="fill-default"
                  ></polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">
                 Easier to Achieve
                </h4>
                <p class="lead text-italic text-white">
                  The Arctic Ocean freezes every winter and much of the sea-ice
                  then thaws every summer, and that process will continue
                  whatever happens.
                </p>
              </blockquote> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
 

              <h3>Small Steps: Embracing Progress One Step at a Time

</h3>
              <p class="lead">
               Life can be overwhelming, and it's easy to get caught up in the idea that we need to make big changes to see significant progress. But the truth is, small steps can lead to big results.
              </p>
              <p>
                By breaking down our goals into smaller, achievable steps, we can make progress without feeling like we're biting off more than we can chew. Taking small steps also helps us build confidence and stay motivated along the way.
              </p>
             <p>Remember, progress is progress, no matter how small. So take that first step, and keep moving forward, one small step at a time. You've got this!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--
    <section class="section section-lg">
      <div class="container">
        <div class="row justify-content-center text-center mb-lg">
          <div class="col-lg-8">
            <h2 class="display-3">{{$t('landing.about.whoWeAre')}}</h2>
            <p class="lead text-muted">
                {{$t('landing.about.details')}}
            </p>
          </div>
        </div>
        <div
          class="row"
          style="justify-content: center;"
          classname="teamSection"
        >
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="px-4">
              <img
                v-lazy="'img/theme/team-1-800x800.jpg'"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                style="width: 200px;"
              />
              <div class="pt-4 text-center">
                <h5 class="title">
                  <span class="d-block mb-1">Muharrem</span>
                  <small class="h6 text-muted">Software Developer</small>
                </h5>
                <div class="mt-3">
                  <base-button
                    tag="a"
                    href="https://twitter.com/mtigdemir"
                    type="warning"
                    icon="fa fa-twitter"
                    rounded
                    icon-only
                  ></base-button>
                  <base-button
                    tag="a"
                    href="#"
                    type="warning"
                    icon="fa fa-facebook"
                    rounded
                    icon-only
                  ></base-button>
                  <base-button
                    tag="a"
                    href="#"
                    type="warning"
                    icon="fa fa-linkedin"
                    rounded
                    icon-only
                  ></base-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="px-4">
              <img
                v-lazy="'img/theme/team-2-800x800.jpg'"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                style="width: 200px;"
              />
              <div class="pt-4 text-center">
                <h5 class="title">
                  <span class="d-block mb-1">Gulistan</span>
                  <small class="h6 text-muted">Frontend Developer</small>
                </h5>
                <div class="mt-3">
                  <base-button
                    tag="a"
                    href="https://twitter.com/gulistan_boylu"
                    type="primary"
                    icon="fa fa-twitter"
                    rounded
                    icon-only
                  ></base-button>
                  <base-button
                    tag="a"
                    href="#"
                    type="primary"
                    icon="fa fa-facebook"
                    rounded
                    icon-only
                  ></base-button>
                  <base-button
                    tag="a"
                    href="#"
                    type="primary"
                    icon="fa fa-linkedin"
                    rounded
                    icon-only
                  ></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    -->
    <section class="section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-3 bg-gradient-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container pt-lg pb-300">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h2 class="display-3 text-white">Building Habits</h2>
            <p class="lead text-white">
              Remember, building habits takes time and effort. Be patient and stay consistent, and soon you'll have developed a positive new habit that sticks.
            </p>
          </div>
        </div>
        <div class="row row-grid mt-5">
          <div class="col-lg-4">
            <h5 class="text-white mt-3">Start small</h5>
            <p class="text-white mt-3">
              When building a new habit, it's important to start small. Focus on one small action that you can do consistently each
              day. 
            </p>
          </div>
          <div class="col-lg-4">
            <h5 class="text-white mt-3">Make it easy</h5>
            <p class="text-white mt-3">
              Make your habit as easy as possible to stick to.
              By making it easy to stick to your habit, you'll be more likely to do it.
            </p>
          </div>
          <div class="col-lg-4">
            <h5 class="text-white mt-3">Track your progress</h5>
            <p class="text-white mt-3">
              Tracking your progress can help you stay motivated and see how far you've come. 
              Celebrate your successes along the way.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg pt-lg-0 section-contact-us">
      <div class="container">
        <div class="row justify-content-center mt--300">
          <div class="col-lg-8">  
            <form 
              name="contactus" 
              action="/thanks" 
              method="post" 
              netlify
              netlify-honeypot="bot-field"
            >
            <card gradient="secondary" shadow body-classes="p-lg-5">
              <h4 class="mb-1">{{$t('landing.contact.getInTouch')}}</h4>
              <p class="mt-0">{{$t('landing.contact.details')}}</p>
              <input type="hidden" name="form-name" value="contactus" />
              <base-input
                name="name"
                class="mt-5"
                alternative
                placeholder="Your name"
                addon-left-icon="ni ni-user-run"
              >
              </base-input>
              <base-input
                type="email"
                name="email"
                alternative
                placeholder="Email address"
                addon-left-icon="ni ni-email-83"
              >
              </base-input>
              <base-input class="mb-4">
                  <textarea
                    class="form-control form-control-alternative"
                    name="message"
                    rows="4"
                    cols="80"
                    placeholder="Type a message..."
                  ></textarea>
                </base-input>
                <button type="submit" class="btn btn-block btn-default btn-lg">{{$t('landing.contact.send')}}</button>
              </card>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
};
</script>
