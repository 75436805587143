import Vue from "vue";
import Router from "vue-router";
import AppFooter from "./layout/AppFooter";
import AppHeader from "./layout/AppHeader";
import Advance from "./views/Advance.vue";
import Landing from "./views/Landing.vue";
// import Login from "./views/Login.vue";
// import Profile from "./views/Profile.vue";
// import Register from "./views/Register.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/advance",
      name: "advance",
      components: {
        header: AppHeader,
        default: Advance,
        footer: AppFooter
      }
    }
    // {
    //   path: "/templates",
    //   name: "templates",
    //   components: {
    //     header: AppHeader,
    //     default: Templates,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: "/login",
    //   name: "login",
    //   components: {
    //     header: AppHeader,
    //     default: Login,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: "/register",
    //   name: "register",
    //   components: {
    //     header: AppHeader,
    //     default: Register,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   components: {
    //     header: AppHeader,
    //     default: Profile,
    //     footer: AppFooter
    //   }
    // }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
