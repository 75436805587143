export default {
  en: {
    menu: {
      home: "Home",
      create: "Create a Chain",
      themes: "Get Inspired"
    },
    landing:{
      heroTitle: "Habit Tracker Templates",
      heroDescription: "Printable, customizable, PDF format habit tracker. Download one of existing template or customize it for yourself for free!",
      createYourOwn: "Create Your Own Chain",
      download: "Download",
      challenges:{
          sugarFree: "21 Days Sugar Free",
          sugarFreeDescription: "",
          reading:  "66 Days Reading",
          readingDescription: "",
          happy: "100 Happy Days",
          happyDescription: ""
      },
      customizeTracker: "Customize Your Tracker",
      features:{
        inspire: "Inspire Yourself",
        font: "Pick Font",
        target: "Decide your target",
        shape: "Choose Shape",
        print: "Print!"
      },
      tags:{
        smart: "Smart",
        lifeStyle: "Life Style",
        health: "Health",
        routine: "Routine"
      },
      about: {
        whoWeAre: "Who we are?",
        details: "We are committed to change our life with achievable smart goals and want us to people aware people to join us :)"
      },
      contact:{
         getInTouch: "Get In Touch With Us!",
         details: "We would love to hear your feedback, let use know what do you think?",
         send: "Send Message"
      }
    },
    advance: {
      inspiration: "Define your goal",
      startDate : "Start Date",
      days: "Duration",
      font: "Font"
    }
  },
  tr: {
    menu: {
      home: "Anasayfa",
      create: "Zincir Olustur",
      themes: "Ilham Al"
    },
    landing:{
      download: "Indir",
      createYourOwn: "Kendi Zincirini Olustur",
      challenges:{
        sugarFree: "21 Gun Sekersiz",
        sugarFreeDescription: "",
        reading:  "66 Gun Okuma",
        readingDescription: "",
        happy: "100 Gun Mutlu Ol",
        happyDescription: ""
      },
    },
    tags:{
    }
  }
};
